import React from "react";
import config from "../../../config";
import { FaPhone, FaEnvelope, FaDirections, FaFacebookF } from "react-icons/fa";
import Layout from "../../components/Layout";

const ContactUsPage = () => {
  return (
    <Layout>
      <div className="section">
        <div className="container">
          <h1 className="is-size-1">How to Contact Us</h1>
          <br />

          <div className="columns is-multiline">
            <div className="column is-half-tablet is-half-desktop is-one-third-widescreen center">
              <div className="box">
                <p>Come See Us</p>
                <FaDirections />
                &nbsp;&nbsp;
                <a href={`http://maps.google.com/?q=` + config.physicalAddress}>
                  {config.physicalAddress}
                </a>
              </div>
            </div>
            <div className="column is-half-tablet is-half-desktop is-one-third-widescreen center">
              <div className="box">
                <p>Call Us</p>
                <FaPhone />
                &nbsp;&nbsp;
                <a href={`tel:` + config.phoneNumber}>{config.phoneNumber}</a>
              </div>
            </div>
            <div className="column is-half-tablet is-half-desktop is-one-third-widescreen center">
              <div className="box">
                <p>Email Us</p>
                <FaEnvelope />
                &nbsp;&nbsp;
                <a href={`mailto:` + config.email}>{config.email}</a>
              </div>
            </div>
            {/* <div className="column is-half center">
              <div className="box">
                <p>Find Us On Facebook</p>
                <FaFacebookF />
                &nbsp;&nbsp;
                <a href={config.facebookUrl}>Facebook</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
